import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useContext, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import ConfirmationModal from "../../modals/confirmationModal";
import UsrConfigErrorModal from "../../modals/userConfigError";
import "../deviceModel/viewDeviceModel.css";
import CustomButton from "../pageHelpers/button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CustomTooltip from "../pageHelpers/toolTip";
import UserRole from "../pageHelpers/userRole";
import ReactPaginate from "react-paginate";

export default function ViewDeviceGroup() {
  const { isAdmin } = UserRole();
  const { userName } = useContext(AuthContext);
  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;

  //to handle data received
  const [deviceGroup, setdeviceGroup] = useState([]);
  const [deviceType, setDeviceType] = useState([]);
  const [deviceModel, setDeviceModel] = useState([]);
  const [value, setvalue] = useState("");
  const [selectedDeviceType, setSelectedDeviceType] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedGroupStatus, setSelectedGroupStatus] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [userConfigErrorDialog, setUserConfigErrorDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle1: "",
  });
  const [formData, setFormData] = useState({
    device_type: "",
    device_model: "",
    group_status: "",
  });
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const { device_type, device_model, group_status } = formData;
  const navigate = useNavigate();
  const { statusCode, errormsg, loading, apiRequest } = useAxiosApiRequest();

  useEffect(() => {
    getDeviceTypeFilter();
    getModelNamesfilter();
    getDeviceGroup(
      `/devicegroupservice/devicegroup/search?page=1&limit=${recordsPerPage}`,
    );
  }, []);

  const buildUrl = () => {
    let baseUrl = `/devicegroupservice/devicegroup/search?limit=${recordsPerPage}&`;
    let params = [];
    if (selectedDeviceType) {
      params.push(`type=${selectedDeviceType}`);
    }
    if (selectedModel) {
      params.push(`model=${selectedModel}`);
    }
    if (selectedGroupStatus) {
      params.push(`status=${selectedGroupStatus}`);
    }
    if (pageNumber) {
      params.push(`page=${pageNumber}`);
    }
    if (params.length > 0) {
      baseUrl += `${params.join("&")}`;
    }
    return baseUrl;
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = buildUrl();
      await getDeviceGroup(url);
    };

    if (
      selectedDeviceType ||
      selectedModel ||
      selectedGroupStatus ||
      pageNumber
    ) {
      fetchData();
    }
  }, [selectedDeviceType, selectedModel, selectedGroupStatus, pageNumber]);

  const handleInputChange = (e) => {
    setvalue(e.target.value);
  };
  const handleClose = () => {
    handleResetFilters();
    setOpen(false);
  };

  const handleApplyFilters = () => {
    // handleFilterSearch();
    setOpen(false);
  };
  const isIconDisabled = (devices) => {
    return devices.some((device) => device.deviceGroupStatus === "Active");
  };

  const handleSearch = async (e) => {
    getDeviceGroup(`/devicegroupservice/devicegroup/search?deviceId=${value}`);
  };

  const handleDeviceTypeChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    const deviceType = e.target.value;
    setSelectedDeviceType(deviceType);
    getModelNameOnDeviceTypeSelected(deviceType);
  };

  const handleDeviceModelChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    const deviceModel = e.target.value;
    setSelectedModel(deviceModel);
  };

  const handleStatusChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    const status = e.target.value;
    setSelectedGroupStatus(status);
  };
  const handleRecordsPerPage = (e) => {
    const records = Number(e.target.value);
    setRecordsPerPage(records);
    setPageNumber(0);
    getDeviceGroup(
      `/devicegroupservice/devicegroup/search?page=${pageNumber}&limit=${recordsPerPage}`,
    );
  };
  const handleResetFilters = () => {
    setFormData({
      device_type: "",
      device_model: "",
      group_status: "",
    });
    setSelectedDeviceType(null);
    setSelectedGroupStatus(null);
    setSelectedModel(null);
    getModelNamesfilter();
    getDeviceGroup(
      `/devicegroupservice/devicegroup/search?page=1&limit=${recordsPerPage}`,
    );
  };

  const handleOpenModal = async (deviceModel) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const updatepayload = {
      name: deviceModel.name,
      deviceGroupStatus:
        deviceModel.deviceGroupStatus === "Active" ? "Inactive" : "Active",
      updatedBy: userName,
    };
    const response = await apiRequest({
      endpoint: `/devicegroupservice/devicegroup`,
      method: "PUT",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: updatepayload,
    });
    if (response) {
      getDeviceGroup(
        `/devicegroupservice/devicegroup/search?page=1&limit=${recordsPerPage}`,
      );
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCreateOnClick = () => {
    navigate("/viewDeviceGroup/AddDeviceGroup");
  };

  const handleUpdateOnClick = (groupStatus, groupName, devices) => {
    if (!isIconDisabled(groupStatus)) {
      navigate(`/viewDeviceGroup/UpdatedeviceGroup/${groupName}`, {
        state: { devices },
      });
    }
  };

  const handleResetSearch = () => {
    if (value) {
      setvalue("");
      getDeviceGroup(
        `/devicegroupservice/devicegroup/search?page=1&limit=${recordsPerPage}`,
      );
    }
  };

  const onHandleCloseUserConfigErrorDialog = () => {
    setUserConfigErrorDialog(false);
  };

  const changePage = (selected) => {
    const page = selected?.nextSelectedPage;
    if (page !== undefined) {
      setPageNumber(page + 1);
    }
  };

  const getDeviceGroup = async (endpoint) => {
    const response = await apiRequest({
      endpoint: endpoint,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.data;
    const totalPages = response?.pagination?.pages;
    const totalRecords = response?.pagination?.total;
    if (Data && Array.isArray(Data)) {
      setdeviceGroup(Data);
      setTotalRecords(totalRecords);
      setTotalPages(totalPages);
    }
  };

  const getModelNameOnDeviceTypeSelected = async (deviceType) => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/allmodels/60/1/deviceType.name/${deviceType}/null/desc`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceModels;
    if (Array.isArray(Data)) {
      const devicemodel = [...new Set(Data.map((item) => item.modelName))];
      setDeviceModel(devicemodel);
    }
  };

  const getDeviceTypeFilter = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceMasterData;
    if (Array.isArray(Data)) {
      setDeviceType(Data);
    }
  };

  const getModelNamesfilter = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/allmodels/60/1/null/null/null/desc`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceModels;
    if (Array.isArray(Data)) {
      const devicemodel = [...new Set(Data.map((item) => item.modelName))];
      setDeviceModel(devicemodel);
    }
  };

  return (
    <Handler statusCode={statusCode} loading={loading} errormsg={errormsg}>
      <div>
        <ConfirmationModal
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        {userConfigErrorDialog && (
          <UsrConfigErrorModal
            openDialogError={userConfigErrorDialog}
            onHandleCloseErrorDialog={onHandleCloseUserConfigErrorDialog}
          />
        )}
        <div className="heading">
          <h4>Device Groups</h4>
          <CustomButton onClick={handleCreateOnClick}>
            Create Device Group
          </CustomButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between", // Use space-between to position elements on the left and right
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* Total Records on the Left */}
          <h6 style={{ margin: 0 }}>Total Records: {totalRecords}</h6>

          {/* Search and Filter Controls on the Right */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: "500px",
              marginLeft: "10px",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search by Device Id..."
              value={value}
              onChange={handleInputChange}
              style={{ flex: 1 }}
              InputProps={{
                endAdornment: value && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleResetSearch} size="small">
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "52px",
                },
              }}
            />
            <IconButton
              onClick={handleSearch}
              style={{
                padding: "6px 10px",
                border: "1px solid #080808",
                backgroundColor: "#080808",
                color: "#ffffff",
                borderRadius: "0 5px 5px 0",
                height: "52px",
              }}
            >
              <SearchIcon />
            </IconButton>

            <button onClick={handleClickOpen} className="actionButton">
              <FilterAltOutlinedIcon titleAccess="Filter" />
            </button>
            <button className="actionButton" onClick={handleResetFilters}>
              <ReplayIcon titleAccess="reset filter" />
            </button>
          </div>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="filter-dialog-title"
          >
            <DialogTitle id="filter-dialog-title">Filter Options</DialogTitle>
            <DialogContent>
              <div style={{ paddingRight: "20px", marginBottom: "10px" }}>
                <FormControl style={{ width: "250px" }} size="small">
                  <label style={{ fontWeight: "bold" }}>Device Type</label>
                  <select
                    className="form-control"
                    id="device_type"
                    value={device_type}
                    required
                    onChange={handleDeviceTypeChange}
                  >
                    <option disabled value={""}>
                      Select Device Type
                    </option>
                    {deviceType &&
                      deviceType.map((getDeviceTypes) => (
                        <option key={getDeviceTypes.id}>
                          {getDeviceTypes.name}
                        </option>
                      ))}
                  </select>
                </FormControl>
              </div>
              <div style={{ paddingRight: "20px", marginBottom: "10px" }}>
                <FormControl style={{ width: "250px" }} size="small">
                  <label style={{ fontWeight: "bold" }}>Device Model</label>
                  <select
                    className="form-control"
                    id="device_model"
                    value={device_model}
                    required
                    onChange={handleDeviceModelChange}
                  >
                    <option disabled value={""}>
                      Select Device Model
                    </option>
                    {deviceModel.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </div>
              <div style={{ paddingRight: "20px", marginBottom: "10px" }}>
                <FormControl style={{ width: "250px" }} size="small">
                  <label style={{ fontWeight: "bold" }}>Group Status</label>
                  <select
                    className="form-control"
                    id="group_status"
                    value={group_status}
                    required
                    onChange={handleStatusChange}
                  >
                    <option disabled value={""}>
                      Select Device Group Status
                    </option>
                    <option value={"Active"}>Active</option>
                    <option value={"Inactive"}>Inactive</option>
                  </select>
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleApplyFilters} color="primary">
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <br></br>
        {deviceGroup.length ? (
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table aria-label="sticky table">
                <TableHead
                  className="table-head"
                  sx={{
                    maxHeight: 20,
                    cursor: "pointer",
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ fontWeight: 700 }} align="center">
                      Group Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }} align="center">
                      Device Type
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }} align="center">
                      Device Model
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }} align="center">
                      Description
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }} align="center">
                      Status
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }} align="center">
                      Total Devices
                    </TableCell>
                    {isAdmin ? (
                      <>
                        <TableCell sx={{ fontWeight: 700 }} align="center">
                          Action
                        </TableCell>
                      </>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deviceGroup &&
                    deviceGroup.map((deviceGroup, index) => {
                      return (
                        <TableRow
                          style={
                            index % 2
                              ? { backgroundColor: "rgba(72, 34, 180, 0.04)" }
                              : { backgroundColor: "white" }
                          }
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={deviceGroup.id}
                        >
                          <TableCell align="center">
                            {deviceGroup.name}
                          </TableCell>
                          <TableCell align="center">
                            {deviceGroup.deviceGroupType}
                          </TableCell>
                          <TableCell align="center">
                            {deviceGroup.deviceGroupModel}
                          </TableCell>
                          <TableCell align="center">
                            {deviceGroup.description}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color:
                                deviceGroup.deviceGroupStatus === "Active"
                                  ? "#2EA611"
                                  : "#CC1414",
                            }}
                          >
                            {deviceGroup.deviceGroupStatus}
                          </TableCell>
                          <TableCell align="center">
                            {deviceGroup.devices?.length}
                          </TableCell>
                          {isAdmin ? (
                            <>
                              <TableCell
                                sx={{
                                  display: "block",
                                }}
                              >
                                <CustomTooltip title={"Update"}>
                                  <FiEdit
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "15px",
                                      fontSize: "110%",
                                      color: isIconDisabled(
                                        deviceGroup.associatedCampaigns,
                                      )
                                        ? "grey"
                                        : "#4822B4",
                                    }}
                                    onClick={() =>
                                      handleUpdateOnClick(
                                        deviceGroup.associatedCampaigns,
                                        deviceGroup.name,
                                        deviceGroup,
                                      )
                                    }
                                  />
                                </CustomTooltip>
                                {deviceGroup.deviceGroupStatus === "Active" ? (
                                  <CustomTooltip
                                    title={"InActivate Device Group"}
                                  >
                                    <CheckCircleIcon
                                      sx={{
                                        color: isIconDisabled(
                                          deviceGroup.associatedCampaigns,
                                        )
                                          ? "grey"
                                          : "green",
                                        // color: "green",
                                        cursor: "pointer",
                                        fontSize: "130%",
                                      }}
                                      onClick={() =>
                                        setConfirmDialog({
                                          isOpen: true,
                                          title: "Inactivate Device Group",
                                          subtitle1:
                                            "All devices associated will be deleted",
                                          onConfirm: () => {
                                            handleOpenModal(deviceGroup);
                                          },
                                        })
                                      }
                                    />
                                  </CustomTooltip>
                                ) : (
                                  <CustomTooltip
                                    title={"Activate Device Group"}
                                  >
                                    <CancelIcon
                                      sx={{
                                        color: "grey",
                                        cursor: "pointer",
                                        fontSize: "130%",
                                      }}
                                      onClick={() =>
                                        setConfirmDialog({
                                          isOpen: true,
                                          title: "Activate Device Group",
                                          subtitle1:
                                            "Are you sure to activate device group?",
                                          onConfirm: () => {
                                            handleOpenModal(deviceGroup);
                                          },
                                        })
                                      }
                                    />
                                  </CustomTooltip>
                                )}
                              </TableCell>
                            </>
                          ) : null}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <Paper className="nodata">No Device Groups Available</Paper>
        )}
        <br></br>
        <div className="pagenate">
          <h6>
            <strong>Total Records : {totalRecords}</strong>
          </h6>
          <div>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="recordsPerPage-label">Show</InputLabel>
              <Select
                labelId="recordsPerPage-label"
                id="recordsPerPage"
                value={recordsPerPage}
                onChange={(e) => {
                  handleRecordsPerPage(e);
                }}
                label="Show"
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </div>

          <span>
            <ReactPaginate
              previousLabel={<KeyboardArrowLeftIcon />}
              nextLabel={<KeyboardArrowRightIcon />}
              breakLabel={"...."}
              marginPagesDisplayed={2}
              pageCount={totalPages}
              forcePage={pageNumber}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </span>
        </div>
      </div>
    </Handler>
  );
}
