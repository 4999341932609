import { Box } from "@mui/material";
import React, { memo } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../notFoundPage";
import Dashboard from "../pages/dashboard/dashboard";
import AddDeviceGroup from "../pages/deviceGroup/addDeviceGroup";
import UpdateDeviceGroup from "../pages/deviceGroup/updateDeviceGroup";
import ViewDeviceGroup from "../pages/deviceGroup/viewDeviceGroup";
import AddDeviceModel from "../pages/deviceModel/createDeviceModel";
import UpdateDeviceModel from "../pages/deviceModel/updateDeviceModel";
import ViewDeviceModel from "../pages/deviceModel/viewDeviceModel";
import DeviceOnboard from "../pages/onboardDevices/onboardDevice";
import UpdateDevice from "../pages/onboardDevices/updateDevice";
import ViewOnboardDevice from "../pages/onboardDevices/viewDevice";
import ViewDeviceData from "../pages/onboardDevices/viewDeviceData";
import OnboardStatus from "../pages/tenantOnboard/onboardStatus";
import TenantOnboard from "../pages/tenantOnboard/tenantOnboard";
import UpdateTenantData from "../pages/tenantOnboard/updateTenantOnboard";
import User from "../pages/userManagement/user";
import Viewusers from "../pages/userManagement/viewUsers";
import ListFirmware from "../pages/firmware/ListFirmware";
import AddEditFirmware from "../pages/firmware/AddEditFirmware";
import FirmwareTimeline from "../pages/firmware/FirmwareTimeline";
import EditUser from "../pages/userManagement/EditUser";
import ViewCampaign from "../pages/campaign/ViewCampaign";
import CreateCampaign from "../pages/campaign/CreateCampaign";
import CampaignSummary from "../pages/campaign/CampaignSummary";

const MainContent = memo(({ role, isToggled }) => {
  const CURRENT_USER_TYPE = role;
  const superAdminRole = "superAdminRole";
  const adminrole = "adminrole";
  const analystrole = "analystrole";

  //defining functions for users which can be used for handling routes
  function AdminElements({ children }) {
    if (
      CURRENT_USER_TYPE.localeCompare(superAdminRole, undefined, {
        sensitivity: "base",
      }) === 0 ||
      CURRENT_USER_TYPE.localeCompare(adminrole, undefined, {
        sensitivity: "base",
      }) === 0
    ) {
      return <>{children}</>;
    } else {
      return null;
    }
  }

  function AdminAnalystElements({ children }) {
    if (
      CURRENT_USER_TYPE.localeCompare(adminrole, undefined, {
        sensitivity: "base",
      }) === 0 ||
      CURRENT_USER_TYPE.localeCompare(analystrole, undefined, {
        sensitivity: "base",
      }) === 0
    ) {
      return <>{children}</>;
    } else {
      return null;
    }
  }

  function AnalystElements({ children }) {
    if (
      CURRENT_USER_TYPE.localeCompare(superAdminRole, undefined, {
        sensitivity: "base",
      }) === 0 ||
      CURRENT_USER_TYPE.localeCompare(adminrole, undefined, {
        sensitivity: "base",
      }) === 0 ||
      CURRENT_USER_TYPE.localeCompare(analystrole, undefined, {
        sensitivity: "base",
      }) === 0
    ) {
      return <>{children}</>;
    } else {
      return <div>Page Not Found</div>;
    }
  }

  function SuperAdminElements({ children }) {
    if (
      CURRENT_USER_TYPE.localeCompare(superAdminRole, undefined, {
        sensitivity: "base",
      }) === 0
    ) {
      return <>{children}</>;
    } else {
      return <div>Page Not Found</div>;
      // <Navigate to={"/"} />;
    }
  }

  function AdminOnlyElements({ children }) {
    if (
      CURRENT_USER_TYPE.localeCompare(adminrole, undefined, {
        sensitivity: "base",
      }) === 0
    ) {
      return <>{children}</>;
    } else {
      return <div>Page Not Found</div>;
      // <Navigate to={"/"} />;
    }
  }
  return (
    <Box
      component="main"
      sx={{
        p: 5,
        // paddingLeft:isToggled?'3%':'2%',
        width: "98%",
        marginTop: "3%",
        boxSizing: "border-box",
        transition: "0.5s",
        marginRight: "-2%",
        // backgroundColor: 'primary.dark',
        // '&:hover': {
        //   backgroundColor: 'primary.main',
        //   opacity: [0.9, 0.8, 0.7],
        // },
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <AnalystElements>
              <Dashboard />
            </AnalystElements>
          }
        />
        <Route
          path="/dashboard"
          element={
            <AnalystElements>
              <Dashboard />
            </AnalystElements>
          }
        />
        <Route
          path="/viewDevice"
          element={
            <AdminAnalystElements>
              <ViewOnboardDevice />
            </AdminAnalystElements>
          }
        />
        <Route
          path="/deviceModels"
          element={
            <AdminAnalystElements>
              <ViewDeviceModel />
            </AdminAnalystElements>
          }
        />
        <Route
          path="/onboardTenant"
          element={
            <SuperAdminElements>
              <TenantOnboard />
            </SuperAdminElements>
          }
        />
        <Route
          path="/OnboardStatus"
          element={
            <SuperAdminElements>
              <OnboardStatus />
            </SuperAdminElements>
          }
        />
        <Route
          path="/user/addUser"
          element={
            <AdminElements>
              <User />
            </AdminElements>
          }
        />
        <Route
          path="/user/editUser/:userName"
          element={
            <AdminElements>
              <EditUser />
            </AdminElements>
          }
        />
        <Route
          path="/user/userList"
          element={
            <AnalystElements>
              <Viewusers />
            </AnalystElements>
          }
        />
        <Route
          path="/viewDeviceGroup"
          element={
            <AdminAnalystElements>
              <ViewDeviceGroup />
            </AdminAnalystElements>
          }
        />
        <Route
          path="/viewCampaign"
          element={
            <AdminAnalystElements>
              <ViewCampaign />
            </AdminAnalystElements>
          }
        />

        <Route
          path="/viewCampaign/CreateCampaign"
          element={
            <AdminOnlyElements>
              <CreateCampaign />
            </AdminOnlyElements>
          }
        />
        <Route
          path="/viewCampaign/CampaignSummary/:campaignId"
          element={
            <AdminAnalystElements>
              <CampaignSummary />
            </AdminAnalystElements>
          }
        />
        <Route
          path="/listFirmware"
          element={
            <AdminAnalystElements>
              <ListFirmware />
            </AdminAnalystElements>
          }
        />
        <Route
          path="/listFirmware/addEditFirmware"
          element={
            <AdminAnalystElements>
              <AddEditFirmware />
            </AdminAnalystElements>
          }
        />

        <Route
          path="/listFirmware/addEditFirmware/:firmwareId"
          element={
            <AdminAnalystElements>
              <AddEditFirmware />
            </AdminAnalystElements>
          }
        />
        <Route
          path="/listFirmware/firmwareTimeline/:firmwareId"
          element={
            <AdminAnalystElements>
              <FirmwareTimeline />
            </AdminAnalystElements>
          }
        />
        <Route
          path="/viewDevice/onboardDevice"
          element={
            <AdminOnlyElements>
              <DeviceOnboard />
            </AdminOnlyElements>
          }
        />
        <Route
          path="/deviceModels/AddDeviceModel"
          element={
            <AdminOnlyElements>
              <AddDeviceModel />
            </AdminOnlyElements>
          }
        />
        <Route
          path="/viewDeviceGroup/AddDeviceGroup"
          element={
            <AdminOnlyElements>
              <AddDeviceGroup />
            </AdminOnlyElements>
          }
        />

        <Route
          path="/viewDevice/Updatedevice/:serialNumber"
          element={
            <AdminOnlyElements>
              <UpdateDevice />
            </AdminOnlyElements>
          }
        />
        <Route
          path="/viewDevice/viewDeviceData/:serialNumber/:device"
          element={
            <AdminOnlyElements>
              <ViewDeviceData />
            </AdminOnlyElements>
          }
        />
        <Route
          path="/OnboardStatus/UpdateTenant/:tenant_id"
          element={
            <SuperAdminElements>
              <UpdateTenantData />
            </SuperAdminElements>
          }
        />
        <Route
          path="/deviceModels/UpdatedeviceModel/:modelNumber"
          element={
            <AdminOnlyElements>
              <UpdateDeviceModel />
            </AdminOnlyElements>
          }
        />
        <Route
          path="/viewDeviceGroup/UpdatedeviceGroup/:groupName"
          element={
            <AdminOnlyElements>
              <UpdateDeviceGroup />
            </AdminOnlyElements>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Box>
  );
});
MainContent.displayName = "MainContent";

export default MainContent;
