import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import log from "loglevel";
import ReactPaginate from "react-paginate";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BasicModal from "../../modals/basicModal";
import ConfirmationModal from "../../modals/confirmationModal";
import SuccessDeleteModal from "../../modals/successModalDelete";
import ErrorModal from "../../modals/errorModal";
// import Progress from "./CampaignSummary";
import { AuthContext } from "../../App";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import "../firmware/ListFirmware.css";
import {
  CAMPAIGN_STATUSCOLORS,
  CAMPIAGN_PRIORITY,
} from "../../constants/constants";

export default function ViewCampaign() {
  const { apiRequest } = useAxiosApiRequest();
  const tenantId = JSON.parse(localStorage.getItem("TenantID"));
  const { tokens } = useContext(AuthContext);
  const { userrole } = tokens;
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle1: "",
  });
  // ----------------------------------------------
  const [searchFilters, setSearchFilters] = useState({
    priority: "",
    group_status: "",
    searchVal: "",
  });
  const { priority, group_status, searchVal } = searchFilters;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchCampaigns({
      limit: recordsPerPage,
      pageNumb: pageNumber,
      searchVal: searchVal,
    });
    let campaignInterval = setInterval(() => {
      fetchCampaigns({
        limit: recordsPerPage,
        pageNumb: pageNumber,
        searchVal: searchVal,
      });
    }, 600000);
    return () => {
      clearInterval(campaignInterval);
    };
  }, [pageNumber, recordsPerPage]);

  const fetchCampaigns = async ({
    limit,
    pageNumb,
    searchVal,
    priority,
    group_status,
  }) => {
    const pathTemplate = `/campaignservice/campaignrequest/${limit}/${pageNumb + 1}/null/desc`;
    const queryParams = [];
    if (searchVal)
      queryParams.push(`searchValue=${encodeURIComponent(searchVal)}`);
    if (priority) queryParams.push(`priority=${priority.toUpperCase()}`);
    if (group_status)
      queryParams.push(`status=${encodeURIComponent(group_status)}`);

    const url =
      queryParams.length > 0
        ? `${pathTemplate}?${queryParams.join("&")}`
        : pathTemplate;
    try {
      const additionalParams = {
        headers: {
          "tenant-id": tenantId,
        },
      };

      const response = await apiRequest({
        endpoint: url,
        method: "GET",
        additionalParams: additionalParams,
        body: {},
      });
      const data = response.campaignRequests;
      const campaignData = data.map((item, index) => ({
        description: item.description,
        status: item.status,
        campaignId: item.campaignId,
        Created_at: item.createdAt,
        Updated_at: item.updatedAt,
        executionStatus: item.executionStatus,
        errorMessage: item.errorMessage,
      }));
      setCampaign(campaignData);
      setTotalPages(response.totalPageCount);
      setTotalRecords(response.totalDocumentCount);
    } catch (err) {
      log.error("Error fetching campaigns:", err);
    }
  };
  const handleSearch = () => {
    fetchCampaigns({
      limit: recordsPerPage,
      pageNumb: 0,
      searchVal: searchVal,
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleResetFilters = () => {
    setSearchFilters({
      priority: "",
      group_status: "",
      searchVal: "",
    });
    fetchCampaigns({
      limit: recordsPerPage,
      pageNumb: pageNumber,
    });
  };
  const handleClose = () => {
    setOpen(false);
    setSearchFilters({
      priority: "",
      group_status: "",
    });
  };

  const handleApplyFilters = () => {
    handleFilterSearch();
    handleClose();
  };
  const handleResetSearch = () => {
    setSearchFilters({ ...searchFilters, searchVal: "" });
  };
  const handleRecordsPerPage = (e) => {
    const records = Number(e.target.value);
    setRecordsPerPage(records);
    setPageNumber(1);
    fetchCampaigns({
      limit: recordsPerPage,
      pageNumb: pageNumber,
      searchVal: "",
    });
  };
  const handleFilterSearch = () => {
    fetchCampaigns({
      limit: recordsPerPage,
      pageNumb: pageNumber,
      priority: priority,
      group_status: group_status,
    });
  };
  const handleSearchChange = (e) => {
    setSearchFilters({ ...searchFilters, searchVal: e.target.value });
  };
  const handleShowSummary = (campaignId) => {
    // setCampaignId(campaignId);
    navigate(`/viewCampaign/CampaignSummary/${campaignId}`);
  };

  const handleCloseDialogs = () => {
    setOpenDialog(false);
    setOpenErrorDialog(false);
  };

  const convertUTCDateToLocalDate = (utcDate) => {
    const localDate = new Date(utcDate);
    const timestamp = new Date(
      localDate.getTime() - localDate.getTimezoneOffset(),
    );
    return timestamp.toLocaleString();
  };
  const changePage = ({ selected }) => {
    // const page = selected?.nextSelectedPage;
    if (selected !== undefined) {
      setPageNumber(selected);
    }
  };

  // const filteredCampaigns = campaign.filter((item) =>
  //   item.deviceId.toLowerCase().includes(searchValue.toLowerCase())
  // );

  return (
    <div>
      <BasicModal open={confirmDialog.isOpen} handleClose={handleCloseDialogs}>
        <p>{confirmDialog.subtitle1}</p>
      </BasicModal>

      {openDialog && (
        <SuccessDeleteModal
          openDialog={openDialog}
          onHandleClose={handleCloseDialogs}
        />
      )}
      {openErrorDialog && (
        <ErrorModal
          openDialogError={openErrorDialog}
          onHandleCloseErrorDialog={handleCloseDialogs}
        />
      )}
      <ConfirmationModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <div className="heading">
        <h4>Campaign List</h4>
        {(userrole.toLowerCase() === "superAdminRole" ||
          userrole.toLowerCase() === "adminrole") && (
          <div>
            <button
              type="submit"
              className="btn btn-dark"
              style={{
                minWidth: "20%",
                marginBottom: "16px",
                padding: "12px 20px",
              }}
              onClick={() => navigate("/viewCampaign/CreateCampaign")}
            >
              Create Campaign
            </button>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginBottom: "16px",
        }}
      >
        <h6 style={{ margin: 0 }}>Total Records: {totalRecords}</h6>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            maxWidth: "500px",
            marginLeft: "10px",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search by Campaign Id..."
            value={searchVal}
            onChange={handleSearchChange}
            style={{ flex: 1 }}
            InputProps={{
              endAdornment: searchVal && (
                <InputAdornment position="end">
                  <IconButton onClick={handleResetSearch} size="small">
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "52px",
              },
            }}
          />
          <IconButton
            onClick={handleSearch}
            style={{
              padding: "6px 10px",
              border: "1px solid #080808",
              backgroundColor: "#080808",
              color: "#ffffff",
              borderRadius: "0 5px 5px 0",
              height: "52px",
            }}
          >
            <SearchIcon />
          </IconButton>

          <button onClick={handleClickOpen} className="actionButton">
            <FilterAltOutlinedIcon titleAccess="Filter" />
          </button>
          <button className="actionButton" onClick={handleResetFilters}>
            <ReplayIcon titleAccess="reset filter" />
          </button>
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="filter-dialog-title"
        >
          <DialogTitle id="filter-dialog-title">Filter Options</DialogTitle>
          <DialogContent>
            <div style={{ paddingRight: "20px", marginBottom: "10px" }}>
              <FormControl style={{ width: "250px" }} size="small">
                <label style={{ fontWeight: "bold" }}>Priority</label>
                <select
                  className="form-control"
                  id="priority"
                  value={priority}
                  required
                  onChange={(e) => {
                    setSearchFilters({
                      ...searchFilters,
                      priority: e.target.value,
                    });
                  }}
                >
                  <option disabled value={""}>
                    Select Priority
                  </option>
                  {CAMPIAGN_PRIORITY &&
                    CAMPIAGN_PRIORITY.map((types, index) => (
                      <option key={index}>{types}</option>
                    ))}
                </select>
              </FormControl>
            </div>
            <div style={{ paddingRight: "20px", marginBottom: "10px" }}>
              <FormControl style={{ width: "250px" }} size="small">
                <label style={{ fontWeight: "bold" }}>Group status</label>
                <select
                  className="form-control"
                  id="group_status"
                  value={group_status}
                  required
                  onChange={(e) => {
                    setSearchFilters({
                      ...searchFilters,
                      group_status: e.target.value,
                    });
                  }}
                >
                  <option disabled value={""}>
                    Select Group status
                  </option>

                  <option key={1}>Active</option>
                  <option key={2}>Inactive</option>
                </select>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleApplyFilters} color="primary">
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <br />
      <Paper sx={{ width: "100%", boxShadow: "none", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className="table-head"
              sx={{
                maxHeight: 20,
                cursor: "pointer",
              }}
            >
              <TableRow>
                <TableCell align="center">SL. no.</TableCell>
                <TableCell align="center">Campaign Id</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="center">Updated At</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Execution Status</TableCell>
                <TableCell align="center">Detail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaign.map((campaignItem, index) => (
                <TableRow key={index} className="tableRowShade" tabIndex={-1}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {campaignItem.campaignId}
                  </TableCell>
                  <TableCell align="center">
                    {campaignItem.description}
                  </TableCell>
                  <TableCell align="center">
                    {campaignItem.Created_at
                      ? convertUTCDateToLocalDate(campaignItem.Created_at)
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {campaignItem.Updated_at
                      ? convertUTCDateToLocalDate(campaignItem.Updated_at)
                      : "-"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color:
                        campaignItem.status.toLowerCase() === "active"
                          ? "green"
                          : "red",
                    }}
                  >
                    {campaignItem.status}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color:
                        CAMPAIGN_STATUSCOLORS[campaignItem.executionStatus] ||
                        "#000",
                    }}
                  >
                    <Tooltip
                      title={campaignItem.errorMessage}
                      arrow
                      placement="top"
                    >
                      <span>{campaignItem.executionStatus}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <VisibilityIcon
                      style={{
                        marginRight: "10px",
                        fontSize: "110%",
                        color: "#1442cc",
                      }}
                      onClick={() => handleShowSummary(campaignItem.campaignId)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <br />
        <div className="pagenate">
          <h6>
            <strong>Total Records : {totalRecords}</strong>
          </h6>
          <div>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="recordsPerPage-label">Show</InputLabel>
              <Select
                labelId="recordsPerPage-label"
                id="recordsPerPage"
                value={recordsPerPage}
                onChange={(e) => {
                  handleRecordsPerPage(e);
                }}
                label="Show"
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </div>

          <span>
            <ReactPaginate
              previousLabel={<KeyboardArrowLeftIcon />}
              nextLabel={<KeyboardArrowRightIcon />}
              breakLabel={"...."}
              marginPagesDisplayed={2}
              pageCount={totalPages}
              forcePage={pageNumber}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </span>
        </div>
      </Paper>
    </div>
  );
}
