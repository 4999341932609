import { Box, Drawer } from "@mui/material";
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import "./navbar.css";
import {
  NavbarDataAdmin,
  NavbarDataAnalyst,
  NavbarDataSuperAdmin,
} from "./navbarData";

import pioLogo from "./pioneer_logo.svg";
//in this component NavbarData is mapping with icons and path and we have
//defined three different conditions for different roles
const SideBar = ({ children, role, isToggled }) => {
  //here user role is updating from Navbar component which is passed as props
  const CURRENT_USER_TYPE = role;
  const superAdminRole = "superAdminRole";
  const adminrole = "adminrole";
  const analystrole = "analystrole";

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
    isToggled();
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Drawer
          variant="permanent"
          sx={{
            flexShrink: 0,
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              // width: 220,
            },
          }}
        >
          <div className="main-container">
            <div className={`sidebar ${!isOpen ? "closed" : ""}`}>
              <div className="top_section">
                {isOpen && <h1 className="logo"></h1>}

                {/* <div className="bars"><FaBars onClick={toggle} /></div> */}
              </div>
              <section className="routes">
                <div className="top_section">
                  {isOpen && <h1 className="logo">MENU</h1>}

                  <div className="bars">
                    {isOpen ? (
                      <IoCloseSharp fontSize={"25px"} onClick={toggle} />
                    ) : (
                      <FaBars onClick={toggle} />
                    )}
                  </div>
                </div>
                {CURRENT_USER_TYPE.localeCompare(adminrole, undefined, {
                  sensitivity: "base",
                }) === 0 &&
                  NavbarDataAdmin.map((route, index) => {
                    return (
                      <NavLink
                        to={route.path}
                        key={index}
                        className="link"
                        activeClassName="active"
                      >
                        <div className="icon">{route.icon}</div>
                        {isOpen && (
                          <div className="link_text">{route.title}</div>
                        )}
                      </NavLink>
                    );
                  })}
                {CURRENT_USER_TYPE.localeCompare(superAdminRole, undefined, {
                  sensitivity: "base",
                }) === 0 &&
                  NavbarDataSuperAdmin.map((route, index) => {
                    return (
                      <NavLink
                        to={route.path}
                        key={index}
                        className="link"
                        activeClassName="active"
                      >
                        <div className="icon">{route.icon}</div>
                        {isOpen && (
                          <div className="link_text">{route.title}</div>
                        )}
                      </NavLink>
                    );
                  })}

                {CURRENT_USER_TYPE.localeCompare(analystrole, undefined, {
                  sensitivity: "base",
                }) === 0 &&
                  NavbarDataAnalyst.map((route, index) => {
                    return (
                      <NavLink
                        to={route.path}
                        key={index}
                        className="link"
                        activeClassName="active"
                      >
                        <div className="icon">{route.icon}</div>

                        {isOpen && (
                          <div className="link_text">{route.title}</div>
                        )}
                      </NavLink>
                    );
                  })}
              </section>
              <footer className="sidebar-footer">
                {isOpen && (
                  <p>
                    Powered by :{" "}
                    <img
                      src={pioLogo}
                      alt="Pioneer Logo"
                      style={{
                        width: "80px",
                        height: "16px",
                        objectFit: "contain",
                      }}
                    />
                  </p>
                )}
              </footer>
            </div>
            <main>{children}</main>
          </div>
        </Drawer>
      </Box>
    </>
  );
};

export default SideBar;
