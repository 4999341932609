import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import { CAMPAIGN_CONSTANTS } from "../../constants/constants";
import Handler from "../../components/handler";
import Breadcrumb from "../../breadCrumbs/navigation";
import "./createCampaign.css";
import { useNavigate } from "react-router-dom";
const CreateCampaign = () => {
  const { statusCode, loading, errormsg, apiRequest } = useAxiosApiRequest();
  const navigate = useNavigate();
  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;
  const [formData, setFormData] = useState({
    tenantId: tenant,
    description: "",
    priority: "",
    groupId: [],
    firmwareVersionId: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    targetSelection: CAMPAIGN_CONSTANTS.targetSelection,
    schedulingConfig: {
      startTime: "",
      endTime: "",
      endBehavior: CAMPAIGN_CONSTANTS.schedulingConfig.endBehavior,
    },
    abortConfig: CAMPAIGN_CONSTANTS.abortConfig,
    jobExecutionsRetryConfig: CAMPAIGN_CONSTANTS.jobExecutionsRetryConfig,
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [deviceModels, setDeviceModels] = useState([]);
  const [groupNames, setGroupNames] = useState([]);
  const [firmwares, setFirmwares] = useState([]);
  const [selectedFirmware, setSelectedFirmware] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({
    deviceType: "",
    deviceModel: "",
  });
  const [days, setDays] = useState(0);
  const PRIORITY_VALUES = ["LOW", "MEDIUM", "HIGH"];
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch device types
        const deviceTypesResponse = await apiRequest({
          endpoint: `/deviceservice/masterdata/devicetype`,
          method: "GET",
          additionalParams: {
            headers: {
              "tenant-id": tenant,
            },
          },
          body: {},
        });
        const Data = deviceTypesResponse?.deviceMasterData;
        if (Array.isArray(Data)) {
          setDeviceTypes(Data);
        }
        // Fetch device models based on selected device type
        if (selectedDevice.deviceType) {
          const deviceModelsResponse = await apiRequest({
            endpoint: `/deviceservice/devicemodel/allmodels/10/1/deviceType.name/${selectedDevice.deviceType}/null/desc`,
            method: "GET",
            additionalParams: {
              headers: {
                "tenant-id": tenant,
              },
            },
            body: {},
          });
          const Data = deviceModelsResponse?.deviceModels;
          if (Array.isArray(Data)) {
            setDeviceModels(Data);
          }
        }

        // Fetch group names based on selected device model
        if (selectedDevice.deviceModel) {
          // /devicegroupservice/devicegroup/search?&status=Active&associatedCampaigns=Inactive&type=deviceType&model=devicemodel
          const groupNamesResponse = await apiRequest({
            endpoint: `/devicegroupservice/devicegroup/search?&status=Active&associatedCampaigns=Inactive&type=${selectedDevice.deviceType}&model=${selectedDevice.deviceModel}`,
            method: "GET",
            additionalParams: {
              headers: {
                "tenant-id": tenant,
              },
            },
            body: {},
          });
          const Data = groupNamesResponse?.data;
          if (Array.isArray(Data)) {
            setGroupNames(Data);
          }
        }

        // Fetch firmware based on selected device type and model
        if (selectedDevice.deviceType && selectedDevice.deviceModel) {
          const firmwareResponse = await apiRequest({
            endpoint: `/firmwareversionservice/search?deviceModel=${selectedDevice.deviceModel}&deviceType=${selectedDevice.deviceType}`,
            method: "GET",
            additionalParams: {
              headers: {
                "tenant-id": tenant,
              },
            },
            body: {},
          });
          const Data = firmwareResponse?.data;
          if (Array.isArray(Data)) {
            setFirmwares(Data);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedDevice.deviceType, selectedDevice.deviceModel]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!value.length) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Value cannot be empty",
      }));
    } else {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    setFormData({ ...formData, [name]: value.trim() });
  };
  const handleDeviceChange = (e) => {
    const { name, value } = e.target;
    setSelectedDevice({ ...selectedDevice, [name]: value });
    setFormData({ ...formData, groupId: [], firmwareVersionId: "" });
  };
  const handleGroupNameChange = (e) => {
    const { value } = e.target;
    setSelectedGroupId(value);
    const selectedGroups = groupNames.filter((group) =>
      value.includes(group.name),
    );
    const groupIds = selectedGroups.map((group) => group._id);
    setFormData({ ...formData, groupId: groupIds });
  };
  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      schedulingConfig: { ...formData.schedulingConfig, [name]: value },
    });
  };
  const handleFirmwareChange = (e) => {
    const { value } = e.target;
    setSelectedFirmware(value);
    const firmwareId = firmwares.find((fw) => fw.firmwareVersion === value);
    if (firmwareId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        firmwareVersionId: [firmwareId._id],
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const submitResponse = await apiRequest({
        endpoint: `/campaignservice/campaignrequest`,
        method: "POST",
        additionalParams: {
          headers: {
            "tenant-id": tenant,
          },
        },
        body: formData,
      });
      if (submitResponse && statusCode === 200) {
        navigate("/viewCampaign");
      }
    } catch (error) {
      console.error("Error submiiting data:", error);
    }
  };

  const calculateEndTime = () => {
    if (formData.schedulingConfig.startTime && days) {
      const currDate = new Date(formData.schedulingConfig.startTime);
      currDate.setMinutes(currDate.getMinutes() + 30);
      const startTime = currDate;
      const endTime = new Date(formData.schedulingConfig.startTime);

      endTime.setDate(endTime.getDate() + parseInt(days));
      endTime.setMinutes(endTime.getMinutes() + 30);

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      };

      const formattedStartTime = formatDate(startTime);
      const formattedEndTime = formatDate(endTime);
      setFormData({
        ...formData,
        schedulingConfig: {
          ...formData.schedulingConfig,
          startTime: formattedStartTime,
          endTime: formattedEndTime,
        },
      });
    }
  };

  useEffect(() => {
    calculateEndTime();
  }, [days]);
  const isFormValid = () => {
    return (
      formData.description &&
      formData.priority &&
      formData.groupId.length > 0 &&
      formData.firmwareVersionId.length &&
      formData.schedulingConfig.startTime
    );
  };
  const today = new Date().toISOString().slice(0, 16);

  return (
    <Handler statusCode={statusCode} loading={loading} errormsg={errormsg}>
      <Breadcrumb />

      <div className="create-campaign-form">
        <h4>Create Campaign</h4>
        <form onSubmit={handleSubmit}>
          <Card style={{ marginBottom: "20px" }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="h8">Device Type</Typography>
                  <FormControl fullWidth>
                    <Select
                      name="deviceType"
                      value={selectedDevice.deviceType}
                      onChange={handleDeviceChange}
                    >
                      {deviceTypes.map((type, index) => (
                        <MenuItem key={index} value={type.name}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h8">Device Model</Typography>
                  <FormControl fullWidth>
                    <Select
                      name="deviceModel"
                      value={selectedDevice.deviceModel}
                      onChange={handleDeviceChange}
                      disabled={!selectedDevice.deviceType}
                    >
                      {deviceModels.map((model, i) => (
                        <MenuItem key={i} value={model.modelName}>
                          {model.modelName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h8">Group Name</Typography>
                  <FormControl fullWidth>
                    <Select
                      multiple
                      name="groupId"
                      value={selectedGroupId}
                      onChange={handleGroupNameChange}
                      disabled={!selectedDevice.deviceModel}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {groupNames && groupNames.length > 0 ? (
                        groupNames.map((group) => (
                          <MenuItem
                            key={group.name}
                            value={group.name}
                            id={group._id}
                          >
                            <Checkbox
                              checked={
                                selectedGroupId.indexOf(group?.name) > -1
                              }
                            />
                            <ListItemText primary={group.name} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No groups available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h8">Firmware</Typography>
                  <FormControl fullWidth>
                    <Select
                      name="firmwareVersionId"
                      value={selectedFirmware}
                      onChange={handleFirmwareChange}
                      disabled={!selectedDevice.deviceModel}
                    >
                      {firmwares.map((fw) => (
                        <MenuItem key={fw.id} value={fw.firmwareVersion}>
                          {fw.firmwareVersion}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h8">Start Time</Typography>
                  <div className="form-group">
                    <input
                      type="datetime-local"
                      name="startTime"
                      className="form-control"
                      value={formData.schedulingConfig.startTime}
                      onChange={handleTimeChange}
                      min={today}
                    />
                  </div>
                  <FormHelperText>
                    Actual campaign start time will be 30 minutes after
                    submission.
                  </FormHelperText>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h8">No. of Days</Typography>
                  <div className="form-group">
                    <input
                      type="number"
                      name="noOfDays"
                      className="form-control"
                      value={days}
                      min={1}
                      onChange={(e) => setDays(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h8">Priority</Typography>
                  <FormControl fullWidth>
                    <Select
                      name="priority"
                      value={formData.priority}
                      onChange={handleInputChange}
                    >
                      {PRIORITY_VALUES.map((val, i) => (
                        <MenuItem key={i} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h8">Description</Typography>
                  <div className="form-group">
                    <textarea
                      // type="text"
                      name="description"
                      className="form-control"
                      value={formData.description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                      style={{ minHeight: "150px", overflow: "auto" }}
                    />
                    {fieldErrors?.description && (
                      <p className="error-message">
                        {fieldErrors?.description}
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <div className="form-group">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isFormValid()}
            >
              Submit Campaign
            </Button>
          </div>
        </form>
      </div>
    </Handler>
  );
};

export default CreateCampaign;
