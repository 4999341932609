import { useState } from "react";
import { useSelector } from "react-redux";

var apigClientFactory = require("aws-api-gateway-client").default;
const LOCAL_URL = {
  firmwareversionservice: ":9024/v2",
  deviceservice: ":9020/v2",
  cognitoservice: ":9014/v2",
  devicegroupservice: ":9026/v2",
  campaignstatus: ":9025/v2",
  campaignservice: ":9022/v2",
};
const useAxiosApiRequest = () => {
  const cred = useSelector((state) => state.cred.cred);
  const tokens = useSelector((state) => state.tokens.tokens);
  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL_v2,
    region: tokens?.region,
    accessKey: cred?.accessKeyId,
    secretKey: cred?.secretAccessKey,
    sessionToken: cred?.sessionToken,
  };
  const apiClient = apigClientFactory.newClient(config);
  const [statusCode, setStatusCode] = useState(null);
  const [errormsg, seterrormsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const apiRequest = async ({ endpoint, method, additionalParams, body }) => {
    setStatusCode(0);
    setLoading(true);
    const [baseUrl, queryString] = endpoint.split("?");
    const queryParams = {};
    if (queryString) {
      const params = new URLSearchParams(queryString);
      params.forEach((value, key) => {
        queryParams[key] = value;
      });
      additionalParams = { ...additionalParams, queryParams };
    }
    let pathTemplate = baseUrl;
    if (process.env.REACT_APP_ENVIRONMENT === "development") {
      let service = baseUrl.split("/")[1];
      let URL = LOCAL_URL[service];
      pathTemplate = URL + baseUrl;
    }
    try {
      const responseData = await apiClient
        .invokeApi({}, pathTemplate, method, additionalParams, body)
        .then((result) => {
          setStatusCode(result?.status);
          setData(result?.data);
          setLoading(false);
          return result?.data;
        })
        .catch((err) => {
          const errorStatusCode = err?.response?.status || "500";
          const errorMessage = err?.response?.data?.message;
          setStatusCode(errorStatusCode);
          seterrormsg(errorMessage);
          setLoading(false);
        });
      return responseData;
    } catch (err) {
      console.error(err);
    }
  };

  return { statusCode, errormsg, loading, data, apiRequest };
};
export default useAxiosApiRequest;
