import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AppBar from "@mui/material/AppBar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import log from "loglevel";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../App";
import "./navbar.css";
// eslint-disable-next-line no-unused-vars
import pioLogo from "./pioneer_logo.svg";
var apigClientFactory = require("aws-api-gateway-client").default;

function Header({ username, onLogout }) {
  const tenant = JSON.parse(localStorage.getItem("TenantID"));
  const { cred } = useContext(AuthContext);
  const { tokens } = useContext(AuthContext);
  const accessKeyId = cred.accessKeyId;
  const SecretKey = cred.secretAccessKey;
  const SessionToken = cred.sessionToken;
  const region = tokens.region;

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL_v2,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(!dropdownOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
  };

  const onHandleSignout = () => {
    onLogout();
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const [userLogo, setuserLogo] = useState("");

  const getUserLogo = async () => {
    var pathTemplate = "/tenantservice/tenant";
    var method = "GET";
    var additionalParams = {
      headers: {
        "Content-Type": "application/json",
        "tenant-id": tenant,
      },
    };

    try {
      await apigClient
        .invokeApi({}, pathTemplate, method, additionalParams, {})
        .then((resp) => {
          const Data = resp.data[0].tenantLogoFilename;
          const themeData = resp.data[0].tenantTheme;
          localStorage.setItem("theme", themeData);
          setuserLogo(Data);
        });
    } catch (err) {
      log.error("Error", err);
    }
  };

  useEffect(() => {
    if (SessionToken && accessKeyId && SecretKey) {
      getUserLogo();
    }
  }, [accessKeyId && SecretKey]);

  useEffect(() => {
    const theme = "pioneer-default";
    document.documentElement.setAttribute("data-theme", theme);
  }, [userLogo]);

  return (
    <>
      <AppBar
        position="fixed"
        className="header"
        style={{ background: "var(--primary-color-header)" }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ justifyContent: "space-between", display: "flex" }}>
          {userLogo && (
            <Typography
              variant="h5"
              component="div"
              sx={{
                // flexGrow: 1,
                display: "flex",
                // justifyContent: "center",
                alignItems: "left",
              }}
            >
              <img
                src={userLogo}
                alt="User Logo"
                style={{ width: "150px", height: "40px", objectFit: "contain" }}
              />
            </Typography>
          )}

          {/* Centered - ConnectX */}
          <Typography
            variant="h5"
            sx={{
              flexGrow: 1, // Ensures this element is centered
              display: "flex",
              justifyContent: "center",
              color: "white",
            }}
          >
            ConnectX
          </Typography>

          {/* Right side - Username and dropdown */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "auto", // Ensures this section is on the right
            }}
          >
            <Typography onClick={handleMenu} sx={{ color: "white" }}>
              {username}
            </Typography>
            {dropdownOpen ? (
              <ArrowDropUpIcon sx={{ color: "white" }} />
            ) : (
              <ArrowDropDownIcon sx={{ color: "white" }} />
            )}
          </div>

          {/* Dropdown Menu */}
          <Menu
            className="usernameMenu"
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>Edit Profile</MenuItem>
            <MenuItem onClick={onHandleSignout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
