import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./status.css";
import { useParams } from "react-router-dom";
import Handler from "../../components/handler";
import Breadcrumb from "../../breadCrumbs/navigation";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";

const CampaignSummary = () => {
  const tenant = JSON.parse(localStorage.getItem("TenantID"));
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const { campaignId } = useParams();

  const [openDialog, setOpenDialog] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [campaignStatus, setCampaignStatus] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [comment, setComment] = useState("");
  const getCampaignStatus = async () => {
    try {
      const pathTemplate = `/campaignstatus/campaignstatusbyid/campaignId/${campaignId}`;
      const additionalParams = { headers: { "tenant-id": tenant } };

      const response = await apiRequest({
        endpoint: pathTemplate,
        method: "GET",
        additionalParams: additionalParams,
        body: {},
      });
      const data = response.vinStatus || [];
      setCampaignStatus(data);
    } catch (err) {
      console.error("Error fetching campaign status:", err);
    }
  };

  const getCampaignSummaryStatus = async () => {
    try {
      const pathTemplate = `/campaignstatus/campaignsummary/campaignId/${campaignId}`;
      const additionalParams = { headers: { "tenant-id": tenant } };

      const response = await apiRequest({
        endpoint: pathTemplate,
        method: "GET",
        additionalParams: additionalParams,
        body: {},
      });
      const data = response.campaignSummary || {};
      setSummaryData(data);
    } catch (err) {
      console.error("Error fetching campaign summary:", err);
      // setError("Failed to fetch campaign summary. Please try again later.");
    }
  };

  const getCampaignStatusbyID = async () => {
    try {
      const pathTemplate = `/campaignservice/campaignrequest/50/1/null/desc?campaignId=${campaignId}`;
      const additionalParams = { headers: { "tenant-id": tenant } };

      const campaignrequestresponse = await apiRequest({
        endpoint: pathTemplate,
        method: "GET",
        additionalParams: additionalParams,
        body: {},
      });
      // setSummaryData(data);
      console.info(campaignrequestresponse.data);
    } catch (err) {
      console.error("Error fetching campaign summary:", err);
      // setError("Failed to fetch campaign summary. Please try again later.");
    }
  };

  useEffect(() => {
    getCampaignStatus();
    getCampaignSummaryStatus();
    getCampaignStatusbyID();
  }, [campaignId]);

  const filteredCampaignData = campaignStatus.filter((job) =>
    job.deviceId.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const handleOpenDialog = () => {
    setComment("");
    setOpenDialog(true);
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Function to handle "Withdraw Campaign" action
  const handleWithdrawCampaign = async () => {
    try {
      const pathTemplate = `/campaignservice/deactivatecampaign/${campaignId}`;
      const additionalParams = { headers: { "tenant-id": tenant } };

      const body = {
        status: "Inactive",
        executionStatus: "CANCELED",
        errorMessage: comment,
      };

      const response = await apiRequest({
        endpoint: pathTemplate,
        method: "PATCH",
        additionalParams: additionalParams,
        body: body,
      });

      const data = response.data.campaignSummary || {};
      setSummaryData(data);
    } catch (err) {
      console.error("Error withdrawing campaign:", err);
    }

    setOpenDialog(false);
  };

  return (
    <Handler statusCode={statusCode} loading={loading}>
      <Breadcrumb />
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: 4 }}
          >
            <Grid item>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Campaign Status
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleOpenDialog}>
                Withdraw Campaign
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={3}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                borderRight="1px solid #d8d8d8"
                p={1}
              >
                <Typography variant="h6">Success</Typography>
                <Typography variant="h5" className="statustwo">
                  {summaryData?.SUCCEEDED || 0}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                borderRight="1px solid #d8d8d8"
                p={1}
              >
                <Typography variant="h6">In Progress</Typography>
                <Typography variant="h5">
                  {summaryData?.IN_PROGRESS || 0}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                borderRight="1px solid #d8d8d8"
                p={1}
              >
                <Typography variant="h6">Failed</Typography>
                <Typography variant="h5">{summaryData?.FAILED || 0}</Typography>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                p={1}
              >
                <Typography variant="h6">Cancelled</Typography>
                <Typography variant="h5">
                  {summaryData?.CANCELED || 0}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Withdraw Campaign</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to withdraw the campaign? This action cannot
              be undone.
            </Typography>

            {/* Text area for comment */}
            <TextField
              label="Comment"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={comment}
              onChange={handleCommentChange}
              sx={{ marginTop: 2 }}
              required
              error={!comment.trim()}
              helperText={!comment.trim() ? "Comment is required" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleWithdrawCampaign(comment)}
              color="primary"
              disabled={!comment.trim()}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Card>

      <Card>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Status of Individual VIN
            </Typography>
            <TextField
              label="Search by Device ID"
              variant="outlined"
              size="small"
              // type="number"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ width: 200 }}
            />
          </Box>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    Sl.No
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    Device ID
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    Stage
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {Array(33).fill(filteredCampaignData[0]).map((vehicleModel, index) => ( */}
                {filteredCampaignData.map((vehicleModel, index) => (
                  <TableRow
                    key={vehicleModel.id}
                    style={{
                      backgroundColor:
                        index % 2 ? "rgba(72, 34, 180, 0.04)" : "white",
                    }}
                    hover
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {vehicleModel.deviceId}
                    </TableCell>
                    <TableCell align="center">
                      {vehicleModel.statusDetails.operation}
                    </TableCell>
                    <TableCell align="center">
                      <div className="progress">
                        <div
                          className={`progress-bar ${
                            vehicleModel.status === "IN_PROGRESS"
                              ? "inProgress"
                              : vehicleModel.status === "SUCCEEDED"
                                ? "done"
                                : vehicleModel.status === "FAILED"
                                  ? "fail"
                                  : ""
                          }`}
                          style={{
                            width: `${vehicleModel.statusDetails.progress}`,
                            opacity: 1,
                          }}
                        >
                          {vehicleModel.statusDetails.progress}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Handler>
  );
};

export default CampaignSummary;
