import React from "react";
import { useSelector } from "react-redux";
import CardsAdmin from "./cardsAdmin";
import { BasicBars } from "./deviceModelsChart";
import SuperadminDashboard from "./superadminDashboard";
import UserSummary from "./userChart";
import CampaignSummaryDashboard from "./campaignSummary";

export default function MyCard() {
  const CURRENT_USER_TYPE = useSelector((state) => state.userType.userType);
  const superAdminRole = "superadminrole";

  return (
    <div>
      {CURRENT_USER_TYPE?.toLowerCase() === superAdminRole ? (
        <SuperadminDashboard />
      ) : (
        <>
          <CardsAdmin />
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <BasicBars />
            <UserSummary />
          </div>
          <CampaignSummaryDashboard />
        </>
      )}
    </div>
  );
}
